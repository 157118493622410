import React, { useState, useEffect } from 'react';
import MarkdownIt from 'markdown-it';
import { process } from './env';
import OpenAI from "openai";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
//import MessageList from './Components/MessageList';
//import Message from './Components/Message';
import './App_new.css';

const options = {
  apiKey: process.env.OPENAI_API_KEY,
// TODO
// Hide the ApiKey from the
// client-side of the
// application.
  dangerouslyAllowBrowser: true
};

const openai = new OpenAI(options);
const md = new MarkdownIt();

// Helper function to initialize the assistant
async function initializeAssistant() {
  const assistant = await openai.beta.assistants.retrieve('asst_LAGTvGROR7Wvxfo4cWxiCWvV');
  return assistant;
}

// Helper function to create a thread
async function createThread() {
  const thread = await openai.beta.threads.create();
  return thread;
}

const App = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [assistant, setAssistant] = useState(null);
  const [thread, setThread] = useState(null);

  // Initialize assistant and thread on mount
  useEffect(() => {
    async function setup() {
      const newAssistant = await initializeAssistant();
      const newThread = await createThread();
      setAssistant(newAssistant);
      setThread(newThread);
    }
    setup();
  }, []);

  const fetchReply = async (userRequest) => {
    try {
      // Add user message to the thread
      await openai.beta.threads.messages.create(thread.id, {
        role: "user",
        content: userRequest.content
      });

      let accumulatedText = '';

      openai.beta.threads.runs.createAndStream(thread.id, { assistant_id: assistant.id })
        .on('textCreated', () => {
          accumulatedText = '';  // Initialize with the first text chunk
          setChatHistory(prevHistory => [...prevHistory, { role: 'assistant', content: '' }]);
        })
        .on('textDelta', (textDelta) => {
          accumulatedText += textDelta.value;  // Accumulate subsequent text chunks
          setChatHistory(prevHistory => {
            const newHistory = [...prevHistory];
            const lastMessage = newHistory[newHistory.length - 1];
            if (lastMessage && lastMessage.role === 'assistant') {
              lastMessage.content = accumulatedText;  // Update the last message with accumulated text
            }
            return newHistory;
          });
        })
        .on('textDone', (textDone) => {
          setChatHistory(prevHistory => {
            const newHistory = [...prevHistory];
            const lastMessage = newHistory[newHistory.length - 1];
            if (lastMessage && lastMessage.role === 'assistant') {
              var finalData = textDone.value;
              var signature = 'Translation QR Code';

              if (finalData.includes(signature))
              {
                var replacement = `[${signature}](http://www.facebook.com/)`;
                finalData = finalData.replace(signature, replacement);
              }

              finalData = finalData.trim();
              lastMessage.content = md.render(finalData, {});  // Update with the Markdown value
            }
            return newHistory;
          });
        });
    } catch (error) {
      console.error("Failed to fetch reply from OpenAI:", error);
    }
  };

  const handleUserSubmit = () => {
    if (!userInput.trim()) return;
    const userMessage = { role: 'user', content: userInput };
    setChatHistory(prevHistory => [...prevHistory, userMessage]);
    fetchReply(userMessage);
    setUserInput('');
  };

  return (
    <div className="chat-container">
      <div style={{ position: "relative", height: "500px" }}>
        <MainContainer>
          <ChatContainer>
            <MessageList >
              {chatHistory.map(({ role, content }, index) => (
                <Message
                  key={index}
                  model={{
                    direction: role === 'assistant' ? 'incoming' : 'outgoing',
                    message: content,
                    type: 'html'
                  }}
                />
              ))}
            </MessageList>

            <MessageInput
              placeholder="How can I advise you?"
              value={userInput}
              onChange={(value) => {setUserInput(value)}}
              onSend={handleUserSubmit}
            />
          </ChatContainer>
        </MainContainer>

        {/*<MessageList messages={chatHistory}>
        </MessageList>

        <input
          style={{
            top: '100%',
          }}
          type='text'
          placeholder="How can I advise you?"
          value={userInput}
          onChange={(event) => {setUserInput(event.target.value)}}
          onKeyDown={(event) => {return (event.key === 'Enter') ? handleUserSubmit() : ''}}
        />
        */}

      </div>
    </div>
  );
};

export default App;













// import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
// import { OpenAI } from 'openai';
// import { process } from './env';
// // import { MessageInput, Message, MessageList, ChatContainer } from '@chatscope/chat-ui-kit-react';
// import {
//   MainContainer,
//   ChatContainer,
//   MessageList,
//   Message,
//   MessageInput,
// } from "@chatscope/chat-ui-kit-react";
// import './App_new.css';

// // import SideBar from "./Component/SideBar"

// // Assuming you have .env.local file with REACT_APP_OPENAI_API_KEY variable set
// /*const configuration = new Configuration({
//   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
// });*/

// const options = new Object();
// options.apiKey = process.env.OPENAI_API_KEY;
// // TODO
// // Hide the ApiKey from the
// // client-side of the
// // application.
// options.dangerouslyAllowBrowser = true;

// const openai = new OpenAI(options);

// // Your existing or modified ChatTemplate component
// const App = () => {
//   const [chatHistory, setChatHistory] = useState([]);
//   const [userInput, setUserInput] = useState('');
  
// //   const [linkInput, setLinkInput] = useState('');
// //   const [qrNameInput, setQrNameInput] = useState('');

//   const fetchReply = async (userRequest) => {
//     try {
//       var updatedHistory = chatHistory.concat(userRequest);

//       let assistant;
//       assistant = await openai.beta.assistants.retrieve('asst_LAGTvGROR7Wvxfo4cWxiCWvV');
      
//       let thread;
//       thread = await openai.beta.threads.create();

      

//       const response = await openai.chat.completions.create({
//         model: 
//         model: "asst_LAGTvGROR7Wvxfo4cWxiCWvV",
//         messages: updatedHistory,
//       });

//       const botReply = response.choices[0].message.content;
//       updatedHistory = updatedHistory.concat({ role: 'assistant', content: botReply });
//       setChatHistory(updatedHistory);
//       // Additional UI or state updates as needed
//     } catch (error) {
//       console.error("Failed to fetch reply from OpenAI:", error);
//     }
//   };

//   // const handleUserSubmit = (e) => {
//   //   e.preventDefault();
//   //   if (! userInput.trim()) return;

//   //   fetchReply({ role: 'user', content: userInput }); // Fetch the reply from OpenAI based on the updated conversation
//   //   setUserInput(''); // Reset input field after submission
//   // };

//   const handleUserSubmit = () => {
//     if (!userInput.trim()) return;

//     // Since there's no default form submission action to prevent,
//     // you can directly proceed with your logic to handle the submission.
//     fetchReply({ role: 'user', content: userInput });
//     setUserInput(''); // Reset input field after submission
//   };

//   return (

// <form className="contact100-form">
// <div className="wrap-input100 validate-input bg1">

//   <div style={{ position: "relative", height: "500px" }}>
//     <MainContainer>
//       <ChatContainer>
//       <MessageList>
//       {
//         chatHistory.map(({role, content}, index) => {
//           return (<Message key={"message"+index} model={{
//             direction: (role == 'assistant') ? 'incoming' : 'outgoing',
//             message: content
//           }} />);
//         })
//       }
//       </MessageList>
        
//       <MessageInput
//         placeholder="How can I advise you?"
//         value={userInput}
//         onChange={setUserInput}
//         onSend={() => handleUserSubmit()} // Use onSend as your submit trigger
//       />
//       </ChatContainer>
//     </MainContainer>
//   </div>
//           </div>
//         </form>

//   );

//   <form onSubmit={handleUserSubmit}>
//         <input
//           type="text"
//           style={{color: 'black'}}
//           value={userInput}
//           onChange={(e) => setUserInput(e.target.value)}
//           placeholder="Type your message here..."
//         />
//         <button type="submit">Send</button>
//       </form>
      
//   return (
//     <div className="container-contact100">
//       <div className="wrap-contact100">
//         <div className="chat-container">
//             {/* Your chat UI rendering logic */}
//             {chatHistory.map(({ role, content }, index) => (
//             <div key={index} className={`chat-message ${role}`}>
//                 <p>{content}</p>
//             </div>
//             ))}
//         </div>
//         <form className="contact100-form" onSubmit={handleUserSubmit}>
//           {/* Additional form inputs for link and QR code name if needed */}
//           <div className="wrap-input100 validate-input bg1">
//             <span className="label-input100">Your QR Code Advisor:</span>
//             <input
//               className="input100"
//               type="text"
//               name="userInput"
//               placeholder="How can i advise you"
//               value={userInput}
//               onChange={(e) => setUserInput(e.target.value)}
//             />
//           </div>
//           <div className="container-contact100-form-btn">
//             <button className="contact100-form-btn">
//               <span>
//                 Send
//                 <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
//               </span>
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// // original one 
//   return (
//     <div className="chat-container">
//       {/* Chat UI rendering logic here */}
//       <MessageList>
//       {
//         chatHistory.map(({role, content}, index) => {
//           return (<Message key={"message"+index} model={{
//             direction: (role == 'assistant') ? 'incoming' : 'outgoing',
//             message: content
//           }} />);
//         })
//       }
//       </MessageList>
//       <form onSubmit={handleUserSubmit}>
//         <input
//           type="text"
//           style={{color: 'black'}}
//           value={userInput}
//           onChange={(e) => setUserInput(e.target.value)}
//           placeholder="Type your message here..."
//         />
//         <button type="submit">Send</button>
//       </form>
//       <ChatContainer style={{height: '500px'}}>
      
//       </ChatContainer>
//     </div>
//   );

// };

// export default App;


// 


// import { OpenAI } from 'openai';
// import { process } from './env';

// // const configuration = new Configuration({
// //     apiKey: process.env.OPENAI_API_KEY,
// // })

// // const openai = new OpenAIApi(configuration)

// const options = new Object();
// options.apiKey = process.env.OPENAI_API_KEY;
// // TODO
// // Hide the ApiKey from the
// // client-side of the
// // application.
// options.dangerouslyAllowBrowser = true;

// const openai = new OpenAI(options);

// const chatbotConversation = document.getElementById('chatbot-conversation')

// const App = () => {

// const conversationArr = [
//     {
//         role: 'system',
//         content: 'You are a highly knowledgeable assistant that is always happy to help.'
//     }
// ] 
 
// document.addEventListener('submit', (e) => {
//     e.preventDefault()
//     const userInput = document.getElementById('user-input')   
//     conversationArr.push({ 
//         role: 'user',
//         content: userInput.value
//     })
//     fetchReply()
//     const newSpeechBubble = document.createElement('div')
//     newSpeechBubble.classList.add('speech', 'speech-human')
//     chatbotConversation.appendChild(newSpeechBubble)
//     newSpeechBubble.textContent = userInput.value
//     userInput.value = ''
//     chatbotConversation.scrollTop = chatbotConversation.scrollHeight
// })

// async function fetchReply(){
//     const response = await openai.createChatCompletion({
//         model: 'gpt-4',
//         messages: conversationArr  
//     })
//     conversationArr.push(response.data.choices[0].message)
//     renderTypewriterText(response.data.choices[0].message.content)
//     console.log(conversationArr)
// /*
// Challenge:
//     1. Pass the completion to the renderTypewriterText function
//        so it updates the DOM.
//     2. Push an object to 'conversationArr'. This object 
//        should have a similar format to the object we pushed 
//        in line 21 but the 'role' should hold the string 
//        'assistant' and the 'content' should hold the completion
//        we get back from the API.
//     3. Log out conversationArr to check it's working.
    
// 🔎 I am going to give you a big hint. To save yourself some 
//    time and work, have a close look at the response before 
//    tackling number 2.
// */
// }

// // {data: {id: "chatcmpl-76GLdLSofnnUt9SIiv0LBPtDB9dR8", object: "chat.completion", created: 1681727165, model: "gpt-4-0314", usage: {prompt_tokens: 31, completion_tokens: 7, total_tokens: 38}, choices: [{message: {role: "assistant", content: "The capital of Tunisia is Tunis."}, finish_reason: "stop", index: 0}]}, status: 200, statusText: "", headers: {cache-control: "no-cache, must-revalidate", content-type: "application/json"}, config: {transitional: {silentJSONParsing: true, forcedJSONParsing: true, clarifyTimeoutError: false}, adapter: xhrAdapter(e), transformRequest: [transformRequest(e,t)], transformResponse: [transformResponse(e)], timeout: 0, xsrfCookieName: "XSRF-TOKEN", xsrfHeaderName: "X-XSRF-TOKEN", maxContentLength: -1, maxBodyLength: -1, validateStatus: validateStatus(e), headers: {Accept: "application/json, text/plain, */*", Content-Type: "application/json", User-Agent: "OpenAI/NodeJS/3.2.1", Authorization: "Bearer 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"}, method: "post", data: "{"model":"gpt-4","messages":[{"role":"system","content":"You are a highly knowledgeable assistant that is always happy to help."},{"role":"user","content":"What is the capital of Tunisia?"}]}", url: "https://api.openai.com/v1/chat/completions"}, request: XMLHttpRequest {}}



// function renderTypewriterText(text) {
//     const newSpeechBubble = document.createElement('div')
//     newSpeechBubble.classList.add('speech', 'speech-ai', 'blinking-cursor')
//     chatbotConversation.appendChild(newSpeechBubble)
//     let i = 0
//     const interval = setInterval(() => {
//         newSpeechBubble.textContent += text.slice(i-1, i)
//         if (text.length === i) {
//             clearInterval(interval)
//             newSpeechBubble.classList.remove('blinking-cursor')
//         }
//         i++
//         chatbotConversation.scrollTop = chatbotConversation.scrollHeight
//     }, 50)
// }
// };

// export default App;







